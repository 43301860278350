import { TOutputData } from '../../../services/body/types';
import { bodyToPlainString } from '../../../services/body/bodyToPlainString';

const READ_SPEED = 200; // 200 words per minute

const getReadTimeFromText = (text: string) => {
  const textLength = text.split(/\s+/).length;

  return Math.floor(textLength / READ_SPEED);
};

export const getReadTimeInMinutesFromBody = (body: TOutputData): number => getReadTimeFromText(bodyToPlainString(body));
