import { gql } from '@apollo/client';
import { articleCommonData } from 'src/components/Article/queries/articleCommonData';

export const updateArticleMutation = gql`
  mutation updateArticle($where: ArticleWhereUniqueInput!, $data: ArticleUpdateInput!) {
    updateArticle(where: $where, data: $data) {
      ${articleCommonData}
      articleLink
      shortDescription
    }
  }
`;
