import React from 'react';
import cx from 'classnames';
import { FloatingArrow, FloatingPortal, UseHoverProps } from '@floating-ui/react';
import { useTooltip } from './hooks/useTooltip';
import { TToolTipDirection } from './types';

export interface TooltipProps {
  children: React.ReactNode | string;
  direction: TToolTipDirection;
  trigger?: React.ReactNode;
  className?: string;
  cursor?: 'pointer' | 'default';
  disableStyling?: boolean;
  floatingUIHover?: UseHoverProps;
}

const Tooltip: React.FC<TooltipProps> = ({
  className,
  children,
  direction,
  trigger,
  cursor = 'pointer',
  floatingUIHover = {},
}) => {
  const { active, x, y, refs, arrowRef, strategy, context, getReferenceProps, getFloatingProps } = useTooltip(
    direction,
    floatingUIHover,
  );

  return (
    <div>
      <div
        className={cx({
          'cursor-pointer': cursor === 'pointer',
        })}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {trigger}
      </div>
      <FloatingPortal>
        {active && (
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 100,
            }}
            {...getFloatingProps()}
            className={cx(
              'max-w-[280px] w-max px-4 py-2 rounded-sm z-10 shadow-staticShadow',
              'text-neutral-200 bg-neutral-700',
              {
                'animate-fade-in': active,
                'animate-fade-out': !active,
              },
              className,
            )}
          >
            <FloatingArrow ref={arrowRef} context={context} className="fill-neutral-700" />
            {children}
          </div>
        )}
      </FloatingPortal>
    </div>
  );
};

export default Tooltip;
